import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classes from './Input.module.scss';

const Text = (props) => {
  const { showError, errorMessage } = props;
  const [inputValue, setInputValue] = useState('');

  const handleChange = (e) => {
    setInputValue(e.target.value);
    props.onChange(props.name, e.target.value);
  };

  const inputClasses = `${inputValue.trim() !== '' ? classes.filled : ''}`;
  
  const parseHtmlString = (html) => {
    return React.createElement('span', { dangerouslySetInnerHTML: { __html: html } });
  };

  return (
    <div className={`${classes.Input} ${showError ? classes.error : ''}`}>
      <div className={classes.inputContainer}>
        {props.type === 'textarea' ? (
          <textarea
            value={inputValue}
            onChange={handleChange}
            maxLength={props.maxLength}
            name={props.name}
            rows={props.rows}
            disabled={props.disabled}
            className={inputClasses}
          />
        ) : (
          <input
            type={props.type}
            value={inputValue}
            onChange={handleChange}
            maxLength={props.maxLength}
            name={props.name}
            disabled={props.disabled}
            className={inputClasses}
          />
        )}
        <label htmlFor={props.name}>{parseHtmlString(props.placeholder)}</label>
      </div>
      {showError ? (
        <div className={classes.ErrorMessage}>{errorMessage}</div>
      ) : null}
    </div>
  );
};

Text.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  rows: PropTypes.number,
};

export default Text;
